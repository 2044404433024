import React, { useMemo } from 'react';
import { get } from 'lodash-es';
import format from 'date-fns/format';
import { Button, Box, makeStyles, Logo } from '@hummingbirdtechgroup/wings-ui';
import {
  Page,
  Tab,
  Tabs,
  Controls,
  FarmsSelect,
  Table,
  Row,
  Header,
  Cell,
  TBody,
  THead,
  ApplicationLink,
} from 'components';
import { useSearchParams } from '@hummingbirdtechgroup/wings-router';
import {
  useGeneratedApplicationList,
  useFieldList,
  useFarmSeasonList,
} from 'services';
import applicationList from 'constants/applicationList';
import { FarmFieldList_farm_fields } from 'services/fields/types/FarmFieldList.gql';
import { FarmGrowingSeasons_growingSeasons } from 'services/farms/types/FarmGrowingSeasons.gql';
import { Application, ApplicationSurvey } from '../../types/application';

const YYYY_MM_DD = 'yyyy-MM-dd';
const HEADER_HEIGHT = '224px';
const LOGO_HEIGHT = '224px';
const CELL_ICON_WIDTH = '40px';
const CELL_DATE_WIDTH = '104px';
const CELL_NAME_WIDTH = '30%';

type NameMap = Record<string, string>;

// @ts-ignore
const useStyles = makeStyles(({ spacing }: any) => ({
  tbody: {
    maxHeight: `calc(100vh - ${HEADER_HEIGHT});`,
    overflow: 'auto',
    overflowY: 'overlay',
  },
  loader: {
    height: `calc(100vh - ${HEADER_HEIGHT});`,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  logo: {
    maxHeight: LOGO_HEIGHT,
    maxWidth: 'auto',
    opacity: 0.5,
    marginBottom: spacing(2),
  },
}));

function getFieldNamesLookupTable(fields: FarmFieldList_farm_fields[]) {
  return () =>
    fields.reduce<NameMap>(
      (acc, curr) => ({ ...acc, [curr.id]: curr.name }),
      {},
    );
}

function checkApplicationAvailable() {
  return (application: Application) =>
    !!applicationList[application.product_type_name];
}

export default function Home(): React.ReactElement {
  const classes = useStyles();

  const [urlQueryState] = useSearchParams();
  const selectedFarm = urlQueryState?.farm;

  const { data: fieldsData, loading: loadingFields } =
    useFieldList(selectedFarm);

  const fields = get(
    fieldsData,
    'farm.fields',
    [],
  ) as FarmFieldList_farm_fields[];

  const { data: seasonsData } = useFarmSeasonList(selectedFarm);

  const seasons = get(
    seasonsData,
    'growingSeasons',
    [],
  ) as FarmGrowingSeasons_growingSeasons[];

  const fieldsNames: NameMap = useMemo(getFieldNamesLookupTable(fields), [
    fields,
  ]);

  const { data: applications = [], isLoading: loadingApplications } =
    useGeneratedApplicationList(selectedFarm);

  const filteredApplications = useMemo(
    () =>
      applications
        .filter(checkApplicationAvailable())
        .map(application => ({
          ...application,
          created_at: new Date(application.created_at),
        }))
        .sort((a, b) => b.created_at.getTime() - a.created_at.getTime()),
    [applications],
  );

  const isLoading = loadingFields || loadingApplications;

  return (
    <Page>
      <Tabs>
        <Tab>Fresh Applications</Tab>
      </Tabs>

      <Controls>
        <FarmsSelect />
        {null && <Button disabled>Create new applications</Button>}
      </Controls>

      <Table>
        <THead>
          <Row>
            <Header cellWidth={CELL_ICON_WIDTH} />
            <Header cellWidth={CELL_DATE_WIDTH}>Date Created</Header>
            <Header cellWidth={CELL_NAME_WIDTH}>Type</Header>
            <Header cellWidth={CELL_DATE_WIDTH}>Survey Date</Header>
            <Header>Field Name</Header>
          </Row>
        </THead>
        <TBody className={classes.tbody}>
          {filteredApplications.map(application => (
            <Row key={application.created_at.getTime()}>
              <Cell cellWidth={CELL_ICON_WIDTH}>
                <Box justifyContent="center">
                  <ApplicationLink
                    application={application}
                    survey={application.survey as ApplicationSurvey}
                    openApplication={
                      applicationList[application.product_type_name]?.webappKey
                    }
                    farmId={selectedFarm}
                    seasons={seasons}
                  />
                </Box>
              </Cell>
              <Cell cellWidth={CELL_DATE_WIDTH}>
                {format(application.created_at, YYYY_MM_DD)}
              </Cell>
              <Cell cellWidth={CELL_NAME_WIDTH}>
                {applicationList[application.product_type_name]?.name}
              </Cell>
              <Cell cellWidth={CELL_DATE_WIDTH}>
                {format(new Date(application.survey.captured_at), YYYY_MM_DD)}
              </Cell>
              <Cell>{fieldsNames[application.survey.field_id]}</Cell>
            </Row>
          ))}
        </TBody>
      </Table>

      {!selectedFarm ? (
        <Box className={classes.loader}>
          <Logo className={classes.logo} />
          No farm has been selected yet. Start by selecting one.
        </Box>
      ) : null}
      {selectedFarm && !filteredApplications.length && !isLoading ? (
        <Box className={classes.loader}>
          <Logo className={classes.logo} />
          No items to show.
        </Box>
      ) : null}
      {isLoading ? (
        <Box className={classes.loader}>
          <Logo className={classes.logo} />
          Loading application data...
        </Box>
      ) : null}
    </Page>
  );
}
